import React, { ReactNode } from "react";
import { css } from "emotion";
import { Color, Colors } from "./Colors";

interface IIconProps {
  children?: ReactNode;
  color?: Color;
  size?: 72 | 24 | 18 | 12;
  disabled?: boolean;
  onClick?(): void | undefined;
}

export const Icon = ({
  color = "text",
  disabled,
  children,
  size = 24,
  onClick,
}: IIconProps) => {
  const textStyle = css`
    color: ${Colors[color]};
    font-family: "Material Icons";
    font-size: ${size}px;
    cursor: ${onClick ? "pointer" : "default"};
    pointer-events: ${disabled ? "none" : "inherit"};
    opacity: ${disabled ? "0.25" : "1"};
    user-select: none;
  `;

  return (
    <span onClick={onClick} className={textStyle}>
      {children}
    </span>
  );
};
