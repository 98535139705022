import React from "react";
import { Colors } from "./Colors";
import { css } from "emotion";

type IToggleProps = {
  children?: React.ReactNode;
  onToggle: (value: boolean) => void;
  value: boolean;
};

export const Toggle = ({ children, onToggle, value }: IToggleProps) => (
  <div
    onClick={() => onToggle(!value)}
    className={css`
      align-items: center;
      border-radius: 4px;
      border: 1px solid ${Colors.darkGrey};
      cursor: pointer;
      display: flex;
      gap: 8px;
      padding: 4px 10px;
    `}
  >
    <div
      className={css`
        background-color: ${value ? Colors.green : Colors.lightGrey};
        border-radius: 50%;
        height: 8px;  
        width: 8px;
      `}
    />
    {children}
  </div>
);
