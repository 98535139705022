
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCFCOs6saidFeObTTZ_TZbmdzdqQ6ihfoU",
    authDomain: "bpvisualizer.firebaseapp.com",
    databaseURL: "https://bpvisualizer.firebaseio.com",
    projectId: "bpvisualizer",
    storageBucket: "bpvisualizer.appspot.com",
    messagingSenderId: "814945973447",
    appId: "1:814945973447:web:e61944add9385d2cd83ae2"
  };

export const firebaseApp = initializeApp(firebaseConfig);

export const db = getFirestore(firebaseApp);
