import React, { ReactNode } from "react";
import { css } from "emotion";
import { Color, Colors } from "./Colors";

interface IPillProps {
  children?: ReactNode;
  color?: Color;
}

export const Pill = ({ color = "green", children }: IPillProps) => (
  <div
    className={css`
      display: flex;
      background-color: ${Colors[color]};
      align-items: center;
      justify-content: center;
      padding: 6px 28px;
      border-radius: 24px;
      flex-shrink: 0;
    `}
  >
    {children}
  </div>
);
