export const Colors = {
  darkGrey: '#aaa',
  lightGrey: '#eee',
  lighterGrey: '#f6f6f6',
  white: '#fff',
  blue: '#0067A5',
  text: '#2c2c2c',
  pink: '#ff7fa4',
  lightPink: '#FFD3E0',
  pinkText: '#3e0012',
  green: '#74f0c1',
  greenText: '#00432a',
  "himmelsblau": '#B3D0ED',
  red: '#FF2222',
  orange: '#FBBB62'
}

export const TextColors: Record<Color, Color> = {
  darkGrey: 'text',
  lightGrey: 'text',
  lighterGrey: 'text',
  white: 'text',
  blue: 'white',
  text: 'white',
  pink: 'pinkText',
  lightPink: 'pinkText',
  pinkText: 'white',
  green: 'greenText',
  greenText: 'white',
  "himmelsblau": 'text',
  red: 'white',
  orange: 'text'
}

export type Color = keyof typeof Colors;
