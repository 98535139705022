import { IReport } from "./services/backend.service";

/** Get the current year from a report */
export const getReportYear = (report: IReport) =>
  parseInt(report.period.split("-")[0]);

/** Used to filter out values in an array that are null or undefined
 *
 * Usage:
 * ```typescript
 * const arr = [1, 2, null, 3, undefined, 4];
 * const nonNullableArr = arr.filter(nonNullable);
 * console.log(nonNullableArr); // [1, 2, 3, 4]
 * ```
 * */
export function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

/** Aggregates reports by year */
export const yearlyReports = (reports: IReport[] | undefined): IReport[] => {
  if (!reports) {
    return [];
  }
  const years = reports.reduce(
    (acc, report) => {
      const year = report.period.split("-")[0];

      if (!acc[year]) {
        acc[year] = {
          period: year,
          accounting_entries: { ...report.accounting_entries },
          timestamp: report.timestamp,
        };
      } else {
        for (const [key, value] of Object.entries(report.accounting_entries)) {
          acc[year].accounting_entries[key] =
            (acc[year].accounting_entries[key] || 0) + value;
        }
      }

      return acc;
    },
    {} as Record<string, IReport>,
  );

  return Object.values(years);
};

const maxTotaltBalances: Record<string, number> = {
  "24": 4 * 1.1 * 76200,
  "23": 4 * 1.1 * 74300,
  "22": 4 * 1.1 * 71000,
  "21": 4 * 1.1 * 68200,
  "20": 4 * 1.1 * 66800,
  "19": 4 * 1.1 * 64400,
};

/** Get the maximum total balance for a given year present in the maxTotaltBalances object
 * If the year is not present in the object, a linear interpolation is used to estimate the value
 */
const getMaximumTotalBalance = (year: number) => {
  if (year in maxTotaltBalances) {
    return maxTotaltBalances[year];
  }

  const years = Object.keys(maxTotaltBalances).map(Number);
  const balances = Object.values(maxTotaltBalances);

  const index = years.findIndex((y) => y > year);
  const x1 = years[index - 1];
  const x2 = years[index];
  const y1 = balances[index - 1];
  const y2 = balances[index];

  return y1 + ((year - x1) * (y2 - y1)) / (x2 - x1);
}

export const hasExceededMaxTotalBalance = (currentReport?: IReport) => {
  if (!currentReport || !currentReport.balance_total) return false;
  const year = getReportYear(currentReport);
  const maxTotalBalance = getMaximumTotalBalance(year);
  return currentReport.balance_total! > maxTotalBalance;
};
