/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/style-prop-object */
import React, { useState, useEffect } from "react";
import { User, onAuthStateChanged } from "firebase/auth";
import { getAuth } from "firebase/auth";
import {
  get30ReportAccounts,
  getEmployees,
  getEmployeeFromUser,
  Employees,
  getAccountNumbers,
  getReportsFor30Percent,
  getReports,
  AccountNumbers,
  IReport,
  Employee,
  shouldUpdateCache,
  clearCache,
  uploadFiles,
} from "./services/backend.service";
import {
  Box,
  Text,
  Icon,
  Currency,
  PeriodName,
  Pill,
  Stack,
  AccountVisualization,
  LoginButton,
  Sparkline,
  Menu,
  Toggle,
} from "./components";
import { css, keyframes } from "emotion";
import {
  getReportYear,
  hasExceededMaxTotalBalance,
  nonNullable,
} from "./utils";
import { useReport } from "./hooks/useReport";
const blink = keyframes`
  to {
    opacity: 1;
  }
`;
const loadingStyle = css`
  animation: ${blink} 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
  opacity: 0;
`;

const App: React.FC<{}> = () => {
  /** The Firebase (Google) User currently signed in */
  const [signedInUser, setSignedInUser] = useState<User | null>(
    getAuth().currentUser,
  );
  /** The employee data for the currently signed in user */
  const [signedInEmployee, setSignedInEmployee] = useState<Employee>();
  /** The currently viewed project number (ie. person) */
  const [projectNumber, setProjectNumber] = useState<number>();
  /** The currently viewed resolution (ie. month or aggregated by year) */
  const [resolution, setResolution] = useState<"month" | "year">("month");
  /** The name of the currently viewed account grouping (ie. Pension or Lön)*/
  const [selectedAccountName, setSelectedAccountName] = useState<string>();

  const [employees, setEmployees] = useState<Employees>();
  const [accountNumbers, setAccountNumbers] = useState<AccountNumbers>();
  const [reports, setReports] = useState<IReport[]>([]);
  const {
    report,
    previousReport,
    nextReport,
    canPreviousReport,
    canNextReport,
  } = useReport(reports, resolution);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [employeeSelectOpen, setEmployeeSelectOpen] = useState<boolean>(false);

  const [view30Percent, setView30Percent] = useState(false);
  const [accountFor30Percent, setAccountFor30Percent] =
    useState<Record<string, number[]>>();

  const updateStoreState = async () => {
    if (signedInUser) {
      setAccountNumbers(await getAccountNumbers());
      setEmployees(await getEmployees());
      const employee = await getEmployeeFromUser(signedInUser);
      if (employee) {
        setSignedInEmployee(employee);
        setProjectNumber(employee.projectNumber);
      }
    }
  };

  const updateViewState = async () => {
    if (projectNumber && signedInUser) {
      const reports = view30Percent
        ? await getReportsFor30Percent()
        : await getReports(projectNumber);
      setReports(reports);
      setSelectedAccountName(undefined);
    }
  };

  const refreshData = async () => {
    clearCache();
    await updateStoreState();
    await updateViewState();
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.gstatic.com/charts/loader.js";
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    updateStoreState();
  }, [signedInUser]);
  useEffect(() => {
    updateViewState();
  }, [projectNumber, signedInUser, view30Percent]);

  useEffect(
    () =>
      onAuthStateChanged(getAuth(), (user) => {
        setSignedInUser(user);
      }),
    [],
  );

  useEffect(() => {
    (async () => {
      if (signedInEmployee) {
        if (await shouldUpdateCache(signedInEmployee)) {
          clearCache();
          updateStoreState();
        }
      }
    })();
  }, [signedInEmployee]);

  const currentEmployee = employees?.find(
    (employee) => employee.projectNumber === projectNumber,
  );

  const { report: yearReport } = useReport(reports, "year");
  const healthBenefit = yearReport?.accounting_entries[7680] ?? 0;

  const exceedingMaxTotalBalance = hasExceededMaxTotalBalance(report);
  const lastReport = reports[reports.length - 1];
  const currentYear = lastReport && getReportYear(lastReport);

  return (
    <Box vertical height="100%" justifyContent="start" alignItems="stretch">
      {signedInEmployee && (
        <Box
          position="relative"
          justifyContent="space-between"
          padding={12}
          color="lighterGrey"
        >
          <Stack spacing={8}>
            <Menu
              onRefreshData={async () => {
                setIsLoading(true);
                await refreshData();
                setIsLoading(false);
              }}
              onUploadData={async (files) => {
                setIsLoading(true);
                await uploadFiles(files);
                await refreshData();
                setIsLoading(false);
              }}
              onView30Percent={async () => {
                setView30Percent(true);
                setSelectedAccountName(undefined);
                const accounts = await get30ReportAccounts();
                setAccountFor30Percent(accounts);
              }}
            />
            <Toggle
              value={resolution === "year"}
              onToggle={() => {
                setResolution((r) => (r === "year" ? "month" : "year"));
              }}
            >
              <Text uppercase style="semibold" color="darkGrey">
                Per år
              </Text>
            </Toggle>
          </Stack>

            {isLoading && (
              <div className={loadingStyle}>
                <Text style="semibold">Laddar…</Text>
              </div>
            )}


          {signedInEmployee && (
            <Text
              onClick={() => setEmployeeSelectOpen(!employeeSelectOpen)}
              uppercase
              style="semibold"
              color="darkGrey"
            >
              {signedInEmployee.name.split(" ")[0]}
            </Text>
          )}
          {employeeSelectOpen && employees && employees.length > 0 && (
            <Box
              position="absolute"
              top="36px"
              right="12px"
              height="200px"
              overflow="auto"
              color="lighterGrey"
              padding={10}
              vertical
              zIndex="1"
            >
              {employees.map((employee) => (
                <Box
                  padding={8}
                  key={employee.projectNumber}
                  onClick={() => {
                    setProjectNumber(employee.projectNumber);
                    setEmployeeSelectOpen(false);
                  }}
                >
                  <Text style="semibold">{employee.name}</Text>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      )}

      {!signedInEmployee && (
        <Box height="100%" justifyContent="center">
          <Stack vertical>
            <Text style="header">Börja med att logga in!</Text>
            <LoginButton callback={setSignedInUser} />
          </Stack>
        </Box>
      )}

      {report && accountNumbers && (
        <>
          <Box vertical padding={20}>
            <Stack alignItems="center" vertical spacing={30}>
              <Stack alignItems="center" spacing={20}>
                <Icon disabled={!canPreviousReport} onClick={previousReport}>
                  arrow_left
                </Icon>
                <Text
                  align="center"
                  minWidth="100px"
                  uppercase
                  style="subheader"
                  onClick={() =>
                    setResolution(resolution === "month" ? "year" : "month")
                  }
                >
                  {resolution === "month" ? (
                    <PeriodName
                      showYear={getReportYear(report) !== currentYear}
                    >
                      {report.period}
                    </PeriodName>
                  ) : (
                    "20" + getReportYear(report)
                  )}
                </Text>
                <Icon disabled={!canNextReport} onClick={nextReport}>
                  arrow_right
                </Icon>
              </Stack>

              {view30Percent && signedInEmployee ? (
                <Stack spacing={6} alignItems="center">
                  <Text uppercase style="semibold" color="pink">
                    Neodevs 30%
                  </Text>
                  <Icon
                    size={18}
                    color="pinkText"
                    onClick={() => {
                      setView30Percent(false);
                      setProjectNumber(signedInEmployee.projectNumber);
                      setSelectedAccountName(undefined);
                    }}
                  >
                    highlight_off
                  </Icon>
                </Stack>
              ) : (
                currentEmployee &&
                signedInEmployee &&
                signedInEmployee.projectNumber !==
                  currentEmployee.projectNumber && (
                  <Stack spacing={6} alignItems="center">
                    <Text uppercase style="semibold" color="pink">
                      {currentEmployee.name}
                    </Text>
                    <Icon
                      size={18}
                      color="pinkText"
                      onClick={() =>
                        setProjectNumber(signedInEmployee.projectNumber)
                      }
                    >
                      highlight_off
                    </Icon>
                  </Stack>
                )
              )}

              {!view30Percent && !selectedAccountName && report.project_no && (
                <>
                  <Text
                    color={exceedingMaxTotalBalance ? "red" : "darkGrey"}
                    style="max"
                  >
                    <Currency>{report.balance_total}</Currency>
                  </Text>
                  {exceedingMaxTotalBalance && (
                    <span
                      className={css`
                        margin-top: -30px;
                      `}
                    >
                      <Text inline>
                        Du har för mycket i din pool. Höj din lön!
                      </Text>
                    </span>
                  )}

                  {report.balance_period && (
                    <Pill color={report.balance_period < 0 ? "pink" : "green"}>
                      <Text style="header" color="white">
                        <Currency showSign>{report.balance_period}</Currency>
                      </Text>
                    </Pill>
                  )}

                  <Box>
                    <Sparkline
                      reports={reports.filter(nonNullable)}
                      currentPeriod={report.period}
                    />
                  </Box>
                </>
              )}
            </Stack>
          </Box>

          <AccountVisualization
            accountEntries={report.accounting_entries}
            accountNumbers={accountNumbers}
            onShowAccountDetails={setSelectedAccountName}
            accountNameToShowDetailsFor={selectedAccountName}
            healthBenefit={healthBenefit}
            accountGrouping={view30Percent ? accountFor30Percent : undefined}
          />
        </>
      )}
    </Box>
  );
};

export default App;
