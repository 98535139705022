import React, { ReactNode } from "react";
import { css, cx } from "emotion";

interface IStackProps {
  children?: ReactNode;
  vertical?: boolean;
  spacing?: number;
  alignItems?: "center";
}

const verticalStyle = (margin: number) => css`
  flex-direction: column;
  & > * {
    margin-bottom: ${margin}px !important;
  }
  & > *:last-child {
    margin-bottom: 0 !important;
  }
`;

const horizontalStyle = (margin: number) => css`
  flex-direction: row;
  & > * {
    margin-right: ${margin}px !important;
  }
  & > *:last-child {
    margin-right: 0 !important;
  }
`;

export const Stack = ({
  vertical,
  children,
  alignItems,
  spacing = 12,
}: IStackProps) => (
  <div
    className={cx(
      css`
        display: flex;
        align-items: ${alignItems};
      `,
      vertical ? verticalStyle(spacing) : horizontalStyle(spacing),
    )}
  >
    {children}
  </div>
);
