import { db, firebaseApp } from "./firebaseWrapper";
import * as store from "store";
import { User } from "firebase/auth";

import { getStorage, ref, uploadBytes } from "firebase/storage";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";

export interface Employee {
  name: string;
  projectNumber: number;
}
export type Employees = Employee[];
export type AccountNumbers = Record<number, string>;
export interface IReport {
  accounting_entries: Record<number, number>;
  balance_period?: number;
  balance_total?: number;
  employee?: string;
  period: string;
  project_no?: number;
  timestamp: string;
}

export const getEmployees = () =>
  new Promise<Employees>(async (resolve, reject) => {
    const cachedResult = store.get("employees");
    if (cachedResult) {
      return resolve(cachedResult);
    }

    const docRef = doc(db, "metadata", "employees");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const numberRecord = docSnap.data()!["employee_numbers"] as Record<
        string,
        number
      >;
      const employees = Object.keys(numberRecord)
        .map((record) => ({
          projectNumber: numberRecord[record],
          name: record,
        }))
        .sort((a, b) => a.name.localeCompare(b.name, "sv"));
      store.set("employees", employees);
      resolve(employees);
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
      reject();
    }
  });

export const getEmployeeFromUser = async (user: User) => {
  const employees = await getEmployees();
  return employees.find((employee) => employee.name === user.displayName!);
};

export const getAccountNumbers = async () =>
  new Promise<AccountNumbers>(async (resolve, reject) => {
    const cachedResult = store.get("accountNumbers");
    if (cachedResult) {
      return resolve(cachedResult);
    }

    const docRef = doc(db, "metadata", "account_schema");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const accountNumbers = docSnap.data()![
        "account_numbers"
      ] as AccountNumbers;
      store.set("accountNumbers", accountNumbers);
      resolve(accountNumbers);
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
      reject();
    }
  });

export const get30ReportAccounts = async () =>
  new Promise<Record<string, number[]>>(async (resolve, reject) => {
    const cachedResult = store.get(`account_groups_30_percent`);
    if (cachedResult) {
      return resolve(cachedResult);
    }

    const docRef = doc(db, "metadata", "account_groups_30");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const accountNumbers = docSnap.data() as Record<string, number[]>;
      store.set("account_groups_30_percent", accountNumbers);
      resolve(accountNumbers);
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
      reject();
    }
  });

export const getReports = async (projectNumber?: number) =>
  new Promise<IReport[]>(async (resolve, reject) => {
    const cachedResult = store.get(`reportsFor${projectNumber}`);
    if (cachedResult) {
      return resolve(cachedResult);
    }
    try {
      const reportQuery = query(
        collection(db, "bp_reports"),
        where("project_no", "==", projectNumber),
      );

      const reports: IReport[] = [];
      const querySnapshot = await getDocs(reportQuery);
      querySnapshot.forEach((doc) => {
        reports.push(doc.data() as IReport);
      });
      store.set(`reportsFor${projectNumber}`, reports);
      resolve(reports);
    } catch {
      reject();
    }
  });

export const getReportsFor30Percent = async () =>
  new Promise<IReport[]>(async (resolve, reject) => {
    const cachedResult = store.get(`reports_30_percent`);
    if (cachedResult) {
      return resolve(cachedResult);
    }
    try {
      const reportQuery30 = query(collection(db, "30_reports"));

      const reports: IReport[] = [];
      const querySnapshot = await getDocs(reportQuery30);
      querySnapshot.forEach((doc) => {
        reports.push(doc.data() as IReport);
      });
      store.set("reports_30_percent", reports);
      resolve(reports);
    } catch {
      reject();
    }
  });

export const clearCache = () => {
  store.clearAll();
  store.set("cacheCleared", new Date());
};

export const shouldUpdateCache = async (signedInEmployee: Employee) => {
  const currentDate = new Date();
  const lastClearedCacheString = store.get("cacheCleared") as
    | string
    | undefined;
  const lastClearedCache = lastClearedCacheString
    ? new Date(lastClearedCacheString)
    : undefined;

  // If we have previously cleared the cache withinn 24 hours we do not need to update the cache
  if (
    lastClearedCache &&
    currentDate.getTime() - lastClearedCache.getTime() <= 1000 * 60 * 60 * 24
  ) {
    return false;
  }

  const reports = await getReports(signedInEmployee.projectNumber);
  const latestReport = reports[reports.length - 1];
  const latestReportYear = Number(latestReport.period.split("-")[0]) + 2000;
  const latestReportMonth = Number(latestReport.period.split("-")[1]);
  const latestReportDate = new Date();
  latestReportDate.setFullYear(latestReportYear);
  latestReportDate.setMonth(latestReportMonth);
  latestReportDate.setDate(1);

  // If the report is not from this month we should update the cache
  if (
    currentDate.getFullYear() >= latestReportDate.getFullYear() &&
    currentDate.getMonth() > latestReportDate.getMonth()
  ) {
    return true;
  }

  // If the report is from the current month we don't need to update the cache
  return false;
};

export const uploadFiles = (files: File[]) =>
  new Promise((resolve, reject) => {
    files.forEach((f) => {
      if (f.name.split(".")[1] === "xls") {
        try {
          console.log("Uploading: " + f.name);

          const storage = getStorage(firebaseApp, "gs://neodev-bp-reports");
          const storageRef = ref(storage, f.name);

          uploadBytes(storageRef, f).then(async (snapshot) => {
            resolve(f);
            console.log("Uploaded a blob or file!", snapshot);
          });
        } catch (error) {
          alert("error uploading file: " + error);
          reject();
        }
      }
    });
  });
