import React, { ReactNode } from "react";
import { css } from "emotion";
import { Color, Colors } from "./Colors";

interface IBoxProps {
  children?: ReactNode;
  color?: Color;
  padding?: number;
  justifyContent?: "space-between" | "center" | "start";
  alignItems?: "stretch" | "self-start";
  vertical?: boolean;
  height?: string;
  position?: "relative" | "absolute";
  top?: string;
  left?: string;
  right?: string;
  overflow?: string;
  onClick?(): void;
  zIndex?: string;
}

export const Box = ({
  children,
  color,
  vertical,
  padding = 0,
  height,
  overflow,
  onClick,
  right,
  justifyContent = "space-between",
  alignItems,
  position,
  top,
  left,
  zIndex,
}: IBoxProps) => (
  <div
    className={css`
      display: flex;
      background-color: ${color ? Colors[color] : "transparent"};
      align-items: center;
      justify-content: ${justifyContent};
      padding: ${padding}px;
      flex-direction: ${vertical ? "column" : "row"};
      align-items: ${alignItems};
      height: ${height};
      position: ${position};
      top: ${top};
      left: ${left};
      right: ${right};
      overflow: ${overflow};
      cursor: ${onClick ? "pointer" : "default"};
      z-index: ${zIndex ? zIndex : undefined};
    `}
    onClick={onClick}
  >
    {children}
  </div>
);
