import { useEffect, useMemo, useState } from "react";
import { IReport } from "../services/backend.service";
import { yearlyReports } from "../utils";

type UseReport = {
  report: IReport;
  nextReport: () => void;
  previousReport: () => void;
  canNextReport: boolean;
  canPreviousReport: boolean;
};

export const useReport = (
  monthlyReports: IReport[],
  resolution: "month" | "year",
): UseReport => {
  const monthlyReportCount = monthlyReports.length;
  const yearlyReportCount = yearlyReports(monthlyReports).length;

  const reports = useMemo(
    () =>
      resolution === "month" ? monthlyReports : yearlyReports(monthlyReports),
    [monthlyReports, resolution],
  );

  // Store the index for each resolution
  const [monthIndex, setMonthIndex] = useState(monthlyReportCount - 1);
  const [yearIndex, setYearIndex] = useState(yearlyReportCount - 1);

  // When reports change, reset the index to the last report
  useEffect(() => {
    setMonthIndex(monthlyReportCount - 1);
    setYearIndex(yearlyReportCount - 1);
  }, [monthlyReportCount, yearlyReportCount]);

  // Set the index based on the current resolution
  const index = resolution === "month" ? monthIndex : yearIndex;
  const setIndex = resolution === "month" ? setMonthIndex : setYearIndex;

  return {
    report: reports[index],
    nextReport: () => {
      if (index < reports.length - 1) {
        setIndex(index + 1);
      }
    },
    previousReport: () => {
      if (index > 0) {
        setIndex(index - 1);
      }
    },
    canNextReport: index < reports.length - 1,
    canPreviousReport: index > 0,
  };
};
