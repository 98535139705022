import React, { ReactNode } from "react";
import { css, cx } from "emotion";
import { Color, Colors } from "./Colors";

interface ITextProps {
  children?: ReactNode;
  color?: Color;
  inline?: boolean;
  style?: "max" | "header" | "subheader" | "body" | "semibold";
  align?: "left" | "right" | "center";
  minWidth?: string;
  uppercase?: boolean;
  onClick?(): void;
}

const styles: Record<Required<ITextProps>["style"], string> = {
  max: css`
    font-size: 72px;
    font-weight: 200;
  `,
  header: css`
    font-size: 24px;
    font-weight: 400;
  `,
  subheader: css`
    font-size: 18px;
    font-weight: 400;
  `,
  semibold: css`
    font-size: 12px;
    font-weight: 600;
  `,
  body: css`
    font-size: 12px;
  `,
};

export const Text = ({
  color = "text",
  style = "body",
  inline = false,
  children,
  uppercase,
  onClick,
  align,
  minWidth,
}: ITextProps) => {
  const cssClass = cx(
    css`
      color: ${Colors[color]};
      font-family: "Source Sans Pro", sans-serif;
      text-transform: ${uppercase ? "uppercase" : "none"};
      letter-spacing: ${uppercase ? "1.2px" : "initial"};
      user-select: none;
      margin: 0;
      min-width: ${minWidth};
      text-align: ${align};
      cursor: ${onClick ? "pointer" : "inherit"};
    `,
    styles[style],
  );

  return inline ? (
    <span onClick={onClick} className={cssClass}>
      {children}
    </span>
  ) : (
    <p onClick={onClick} className={cssClass}>
      {children}
    </p>
  );
};
