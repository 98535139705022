/* eslint-disable react/style-prop-object */
import React, { useState, useRef } from "react";
import { Icon } from "./Icon.component";
import { Box } from "./Box.component";
import { Text } from "./Text.component";
import { css } from "emotion";
interface IMenuProps {
  onRefreshData(): void;
  onView30Percent(): void;
  onUploadData(files: File[]): Promise<void>;
}

export const Menu: React.FC<IMenuProps> = ({
  onRefreshData,
  onView30Percent,
  onUploadData,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <Icon onClick={() => setIsOpen(!isOpen)} size={24}>
        menu
      </Icon>
      {isOpen && (
        <Box position="absolute" left="4em">
          <Box
            position="absolute"
            top="25px"
            left="-55px"
            height="auto"
            overflow="auto"
            color="lighterGrey"
            padding={10}
            vertical
            alignItems="self-start"
            zIndex="1"
          >
            <Box
              padding={12}
              onClick={() => {
                onRefreshData();
                setIsOpen(false);
              }}
            >
              <Text style="semibold">Ladda om data</Text>
            </Box>
            <Box
              padding={12}
              onClick={() => {
                fileInputRef.current?.click();
              }}
            >
              <Text style="semibold">Ladda upp månadsrapporter</Text>
            </Box>
            <Box
              padding={12}
              onClick={() => {
                onView30Percent();
                setIsOpen(false);
              }}
            >
              <Text style="semibold">Neodev Gemensamt</Text>
            </Box>
            <input
              ref={fileInputRef}
              type="file"
              accept=".xls"
              multiple
              className={css`
                display: none;
              `}
              onChange={async (data) => {
                const files = [...(data.target.files as unknown as File[])];
                await onUploadData(files);
                setIsOpen(false);
              }}
            />
          </Box>
        </Box>
      )}
    </>
  );
};
