/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode } from "react";

interface IPeriodNameProps {
  children?: ReactNode;
  showYear?: boolean;
}

export const PeriodName = ({ showYear, children }: IPeriodNameProps) => {
  if (typeof children !== "string") {
    throw new Error("Child must be a string with the format YY-MM");
  }
  const [year, month] = children.split("-").map((val) => Number(val));
  const date = new Date();
  date.setDate(1);
  date.setMonth(month - 1);
  date.setFullYear(year + 2000);
  return (
    <>
      {date.toLocaleString("sv-SE", {
        month: "long",
        year: showYear ? "numeric" : undefined,
      })}
    </>
  );
};
