import React, { ReactNode } from "react";

interface ICurrencyProps {
  children?: ReactNode;
  showSign?: boolean;
  hideCurrency?: boolean;
}

export const Currency = ({
  showSign,
  hideCurrency = false,
  children,
}: ICurrencyProps) => {
  const value = children as number;
  if (typeof value !== "number") {
    throw new Error("Child must be a number");
  }

  return (
    <>
      {value.toLocaleString("sv-SE", {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        signDisplay: showSign ? "exceptZero" : undefined,
        currency: !hideCurrency ? "SEK" : undefined,
        style: !hideCurrency ? "currency" : undefined,
        currencyDisplay: !hideCurrency ? "symbol" : undefined,
      } as any)}
    </>
  );
};
