/* eslint-disable react/style-prop-object */
import React, { useEffect, useRef } from "react";
import { IReport } from "../services/backend.service";
import { css } from "emotion";

interface ISparklineProps {
  reports: IReport[];
  currentPeriod: string;
}

export const Sparkline: React.FC<ISparklineProps> = ({
  reports,
  currentPeriod,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current || reports.length < 1) {
      return;
    }
    google.charts.load("current", { packages: ["corechart"] });
    google.charts.setOnLoadCallback(() => {
      const filteredReports = reports.filter(
        (report) =>
          Number(report.period.split("-").join("")) <=
          Number(currentPeriod.split("-").join("")),
      );
      const data = google.visualization.arrayToDataTable([
        ["Period", "Total"],
        ...filteredReports.map((report) => {
          return [report.period, report.balance_total];
        }),
      ]);

      var options = {
        curveType: "function",
        legend: "none",
        enableInteractivity: false,
        width: 370,
        // animation: {
        //   duration: 250,
        //   easing: 'inAndOut',
        //   startup: true,
        // },
        hAxis: {
          gridlines: {
            count: 0,
          },
          textPosition: "none",
          baselineColor: "transparent",
        },
        vAxis: {
          gridlines: {
            count: 0,
          },
          textPosition: "none",
          baseline: 0,
          baselineColor: "#eee",
          viewWindowMode: "maximized",
        },
      } as google.visualization.LineChartOptions;

      var chart = new google.visualization.LineChart(ref.current!);

      chart.draw(data, options);
    });
  }, [ref, reports, currentPeriod]);

  //TODO: Update chart with new data when props change instead of re-initiating it.

  return (
    <div
      className={css`
        height: 50px;
      `}
      ref={ref}
      id="chart"
    />
  );
};
